const { $i18n} = useNuxtApp()
const rbac = useRbac()

export default defineNuxtRouteMiddleware((to) => {

  const meta: any = typeof to.meta.rbac === 'object' ? {
    can: null,
    ...to.meta.rbac
  } : to.meta.rbac

  if (meta.can !== null && !rbac.can(meta.can)) {
    return abortNavigation({
      statusCode: 401,
      message: $i18n.t('error.access')
    })
  }

  return
})
